import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

import { removeFromSessionStorage } from '@hubcms/utils-browser';
import { log } from '@hubcms/utils-monitoring';
import { AuthContext } from '@hubcms/data-access-auth';
import type { ApiIdentity } from '@hubcms/domain-auth';

const SESSION_STORAGE_KEY = 'oauth_idtoken';

export const OAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<null | ApiIdentity['user']>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    try {
      const userDataString = document.getElementById('user-data')?.textContent;
      const userData = userDataString ? JSON.parse(userDataString) : null;

      if (userData && userData.user) {
        userData.user.givenName = userData.user.given_name;
        userData.user.familyName = userData.user.family_name;
        setUser(userData.user);
        setIsAuthenticated(Object.keys(userData.user).length > 0);
      }
    } catch (e) {
      log(e);
    } finally {
      setIsLoading(false);
    }
    return () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
  }, []);

  const login = (returnUrl?: string) => {
    if (!isAuthenticated) {
      window.location.href = '/auth/login/';
    }
    window.location.href = returnUrl || '/';
  };

  const logout = (logoutServiceUrl?: string) => {
    fetch(`/api/auth/profile/?logout=true`, {
      credentials: 'include',
    })
      .then(r => r.json())
      .then(() => removeFromSessionStorage(SESSION_STORAGE_KEY))
      .finally(() => {
        window.location.href = logoutServiceUrl || '/';
      })
      .catch(e => {
        throw e;
      });
    return false; // no redirects; presume that the service is called directly
  };

  return <AuthContext.Provider value={{ user, login, logout, isAuthenticated, isLoading }}>{children}</AuthContext.Provider>;
};
